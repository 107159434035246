<template>
  <!--工具详情-->
  <div class="page">
    <van-nav-bar
      fixed
      :title="'工具'"
      left-arrow
      @click-left="navBack"
    ></van-nav-bar>
    <div class="container">
      <van-form ref="form">
        <van-field
          v-model="toolsData.toolCode"
          readonly
          clickable
          required
          name="prodId"
          label="工具编号"
          placeholder="点击选择工具编号"
          input-align="right"
          :rules="[
            {
              required: true,
              trigger: 'onChange'
            }
          ]"
          @click="selectPicker('toolCode', '工具编号')"
        >
        </van-field>
        <van-cell v-model="toolsData.toolName" title="工具名称" />
        <van-cell v-model="toolsData.classType" title="规格型号" />
        <van-cell v-model="toolsData.unitId" title="计量单位编号" />
        <van-cell v-model="toolsData.unitName" title="计量单位名称" />
        <van-cell v-model="toolsData.remark" title="计划备注" />

        <van-field
          v-model="toolsData.count"
          clickable
          type="number"
          name="quantity"
          label="使用数量"
          placeholder="请填写使用数量"
          input-align="right"
          :rules="[
            { pattern: /^-?(^\d{1,23})*(\.\d{1,6})?$/, trigger: 'onChange' }
          ]"
          :readonly="!editable"
        >
        </van-field>

        <van-field
          v-model="toolsData.brandName"
          readonly
          clickable
          name="brandName"
          label="品牌"
          placeholder="点击选择品牌"
          input-align="right"
          @click="selectPicker('brand', '品牌')"
        >
        </van-field>

        <van-field
          v-model="toolsData.execRemark"
          clickable
          type="textarea"
          name="execRemark"
          label="实施备注"
          placeholder="请填写实施备注"
          input-align="right"
          maxlength="500"
          show-word-limit
          :readonly="!editable"
        >
        </van-field>
      </van-form>
      <bottom-fixed-box>
        <van-button v-if="editable" type="info" @click="save()">
          保存
        </van-button>
      </bottom-fixed-box>
    </div>

    <select-popup
      :visible="showSelectPicker"
      :choose-value="showSelectPickerKey"
      :title="showSelectPickerTitle"
      :show-filter="true"
      :data="options"
      :value-key="selectPickerValue"
      :label-key="selectPickerLabel"
      :popup-style="{ height: '50vh' }"
      @confirm="onSelectPickerConfirm"
      @close="showSelectPicker = false"
    />
  </div>
</template>

<script>
import { getSpareparts, getBasicParams, updateLubImelpment } from "./api";
import BottomFixedBox from "@/components/general/BottomFixedBox";

export default {
  name: "ToolsEdit",
  components: {
    BottomFixedBox
  },
  props: {
    form: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: function() {
        return {};
      }
    },
    idx: {
      type: Number
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      toolsData: {},
      selectPickerValue: "value",
      selectPickerLabel: "label",
      showSelectPickerKey: "",
      showSelectPickerTitle: "",
      showSelectPicker: false,

      options: [1, 2],
      asyncOptions: {
        toolCode: [], //备件编号
        brand: [] //品牌
      }
    };
  },

  created() {
    this.getSpareparts();
    this.getOptions("brand", "brand"); //品牌
  },
  mounted() {
    // 等待整个视图渲染完毕
    this.$nextTick(() => {
      this.toolsData = this.form.toolDTOS[this.idx];
    });
  },
  methods: {
    save() {
      this.$refs.form.validate().then(async () => {
        await updateLubImelpment(this.form);
        this.$toast.success("保存成功！");
        this.$router.back();
      });
    },
    getSpareparts() {
      getSpareparts().then(res => {
        this.asyncOptions.toolCode = res.list;
      });
    },
    // 根据基础编码获取基础数据
    getOptions(codeId, prop) {
      getBasicParams(codeId).then(res => {
        this.asyncOptions[prop] = res.unDelete;
      });
    },
    selectPicker(key, title) {
      if (!this.editable) return;
      this.options = this.asyncOptions[key];
      this.showSelectPickerKey = key;
      this.showSelectPickerTitle = title;
      if (key === "toolCode") {
        this.selectPickerValue = "prodId";
        this.selectPickerLabel = "chName";
      } else {
        this.selectPickerValue = "value";
        this.selectPickerLabel = "label";
      }
      this.showSelectPicker = true;
    },
    onSelectPickerConfirm(val) {
      if (this.showSelectPickerKey === "toolCode") {
        this.toolsData.toolCode = val.prodId;
        this.toolsData.toolName = val.chName;
        this.toolsData.classType = val.forPlastic;
        this.toolsData.unitId = val.packUnitId;
        this.toolsData.unitName = val.packUnit;
      } else {
        this.$set(this.toolsData, this.showSelectPickerKey, val.value);
        this.$set(this.toolsData, this.showSelectPickerKey + "Name", val.label);
      }
      this.showSelectPicker = false;
    },

    navBack() {
      if (this.editable) {
        this.$dialog
          .confirm({
            title: "提醒",
            message: "数据尚未保存，确认返回？"
          })
          .then(() => {
            this.$router.back();
          });
        return;
      }
      this.toolsData = {};
      this.$router.back();
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
}

.container {
  height: 750px;
  margin-top: 58px;
}
</style>
