var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('van-nav-bar',{attrs:{"fixed":"","title":'工具',"left-arrow":""},on:{"click-left":_vm.navBack}}),_c('div',{staticClass:"container"},[_c('van-form',{ref:"form"},[_c('van-field',{attrs:{"readonly":"","clickable":"","required":"","name":"prodId","label":"工具编号","placeholder":"点击选择工具编号","input-align":"right","rules":[
          {
            required: true,
            trigger: 'onChange'
          }
        ]},on:{"click":function($event){return _vm.selectPicker('toolCode', '工具编号')}},model:{value:(_vm.toolsData.toolCode),callback:function ($$v) {_vm.$set(_vm.toolsData, "toolCode", $$v)},expression:"toolsData.toolCode"}}),_c('van-cell',{attrs:{"title":"工具名称"},model:{value:(_vm.toolsData.toolName),callback:function ($$v) {_vm.$set(_vm.toolsData, "toolName", $$v)},expression:"toolsData.toolName"}}),_c('van-cell',{attrs:{"title":"规格型号"},model:{value:(_vm.toolsData.classType),callback:function ($$v) {_vm.$set(_vm.toolsData, "classType", $$v)},expression:"toolsData.classType"}}),_c('van-cell',{attrs:{"title":"计量单位编号"},model:{value:(_vm.toolsData.unitId),callback:function ($$v) {_vm.$set(_vm.toolsData, "unitId", $$v)},expression:"toolsData.unitId"}}),_c('van-cell',{attrs:{"title":"计量单位名称"},model:{value:(_vm.toolsData.unitName),callback:function ($$v) {_vm.$set(_vm.toolsData, "unitName", $$v)},expression:"toolsData.unitName"}}),_c('van-cell',{attrs:{"title":"计划备注"},model:{value:(_vm.toolsData.remark),callback:function ($$v) {_vm.$set(_vm.toolsData, "remark", $$v)},expression:"toolsData.remark"}}),_c('van-field',{attrs:{"clickable":"","type":"number","name":"quantity","label":"使用数量","placeholder":"请填写使用数量","input-align":"right","rules":[
          { pattern: /^-?(^\d{1,23})*(\.\d{1,6})?$/, trigger: 'onChange' }
        ],"readonly":!_vm.editable},model:{value:(_vm.toolsData.count),callback:function ($$v) {_vm.$set(_vm.toolsData, "count", $$v)},expression:"toolsData.count"}}),_c('van-field',{attrs:{"readonly":"","clickable":"","name":"brandName","label":"品牌","placeholder":"点击选择品牌","input-align":"right"},on:{"click":function($event){return _vm.selectPicker('brand', '品牌')}},model:{value:(_vm.toolsData.brandName),callback:function ($$v) {_vm.$set(_vm.toolsData, "brandName", $$v)},expression:"toolsData.brandName"}}),_c('van-field',{attrs:{"clickable":"","type":"textarea","name":"execRemark","label":"实施备注","placeholder":"请填写实施备注","input-align":"right","maxlength":"500","show-word-limit":"","readonly":!_vm.editable},model:{value:(_vm.toolsData.execRemark),callback:function ($$v) {_vm.$set(_vm.toolsData, "execRemark", $$v)},expression:"toolsData.execRemark"}})],1),_c('bottom-fixed-box',[(_vm.editable)?_c('van-button',{attrs:{"type":"info"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" 保存 ")]):_vm._e()],1)],1),_c('select-popup',{attrs:{"visible":_vm.showSelectPicker,"choose-value":_vm.showSelectPickerKey,"title":_vm.showSelectPickerTitle,"show-filter":true,"data":_vm.options,"value-key":_vm.selectPickerValue,"label-key":_vm.selectPickerLabel,"popup-style":{ height: '50vh' }},on:{"confirm":_vm.onSelectPickerConfirm,"close":function($event){_vm.showSelectPicker = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }